<script setup>
import { onMounted, ref } from 'vue'
import { getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import Header from '../components/Header.vue'
import healthImage1 from '@/assets/imgs/health.png'
import healthImage2 from '@/assets/imgs/health1.png'
import healthImage3 from '@/assets/imgs/health2.png'

// Initialize Firestore and Auth
const db = getFirestore()
const auth = getAuth()

// Reactive variables
const articles = ref([])
const title = ref('')
const content = ref('')
const newComment = ref('')
const isAdmin = ref(false)
const resources = ref([
  {
    id: 1,
    title: 'Understanding Anxiety',
    image: healthImage1,
    url: ''
  },
  {
    id: 2,
    title: 'Managing Depression',
    image: healthImage2,
    url: ''
  },
  {
    id: 3,
    title: 'Coping with Stress',
    image: healthImage3,
    url: ''
  }
])

// Load resources from Firestore on mount
onMounted(async () => {
  // Fetch articles from Firestore
  await fetchArticles()

  // Check if the user is logged in and is an admin
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))
  if (loggedInUser && loggedInUser.role === 'Admin') {
    isAdmin.value = true
  }
})

// Fetch articles from Firestore
const fetchArticles = async () => {
  const articlesCollection = collection(db, 'articles') // Use the 'articles' collection in Firestore
  const articleSnapshot = await getDocs(articlesCollection)
  articles.value = articleSnapshot.docs.map(doc => ({
    id: doc.id, 
    ...doc.data(),
    createdAt: doc.data().createdAt?.toDate() || new Date() // Ensure Firestore Timestamp is converted to JS Date
  }))
}

// Store articles to Firestore
const submitArticle = async () => {
  try {
    await addDoc(collection(db, 'articles'), {
      title: title.value,
      content: content.value,
      comments: [],
      ratings: [],
      likes: 0,
      averageRating: 0,
      userRating: 0,
      createdAt: serverTimestamp() // Store server timestamp on article creation
    })
    // Clear inputs after successful submission
    title.value = ''
    content.value = ''
    await fetchArticles() // Reload articles
  } catch (error) {
    console.error('Error adding article:', error)
  }
}

// Add comments to an article in Firestore
const addComment = async (articleId) => {
  const article = articles.value.find((a) => a.id === articleId)
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))

  if (article && loggedInUser) {
    // Add the comment to the article's comments
    const updatedComments = [
      ...article.comments,
      {
        id: article.comments.length + 1,
        text: newComment.value,
        username: loggedInUser.username.split('@')[0]
      }
    ]

    // Update the document in Firestore
    await updateDoc(doc(db, 'articles', articleId), {
      comments: updatedComments
    })

    // Reload the articles after update
    await fetchArticles()
    newComment.value = ''
  } else {
    console.error('Article not found or user not logged in')
  }
}

// Rate an article in Firestore
const rateArticle = async (articleId, rating) => {
  const article = articles.value.find((a) => a.id === articleId)

  if (article) {
    // Update ratings array
    const updatedRatings = [...article.ratings, rating]
    const averageRating = (updatedRatings.reduce((sum, current) => sum + current, 0) / updatedRatings.length).toFixed(1)

    // Update Firestore document
    await updateDoc(doc(db, 'articles', articleId), {
      ratings: updatedRatings,
      averageRating: parseFloat(averageRating),
      userRating: rating
    })

    // Reload the articles after update
    await fetchArticles()
  } else {
    console.error('Article not found')
  }
}

// Like an article in Firestore
const likeArticle = async (articleId) => {
  const article = articles.value.find((a) => a.id === articleId)

  if (article) {
    const updatedLikes = article.likes ? article.likes + 1 : 1
    await updateDoc(doc(db, 'articles', articleId), {
      likes: updatedLikes
    })

    // Reload articles after update
    await fetchArticles()
  }
}

// Delete all comments for an article
const deleteAllComments = async (articleId) => {
  const article = articles.value.find((a) => a.id === articleId)
  if (article) {
    await updateDoc(doc(db, 'articles', articleId), {
      comments: []
    })
    await fetchArticles()
  }
}
</script>

<template>
  <div class="article-page">
    <Header />
    <div class="main">
      <div class="left">
        <form @submit.prevent="submitArticle">
          <div class="contentBox">
            <label for="title">Title</label>
            <input v-model="title" type="text" id="title" required />
          </div>
          <div class="contentBox">
            <label for="content">Content</label>
            <textarea v-model="content" id="content" required></textarea>
          </div>
          <button type="submit" class="btn btn-success">Publish</button>
        </form>
      </div>
      <div class="middle">
        <h2>Published Articles</h2>
        <ul class="article-list">
          <li v-for="article in articles" :key="article.id" class="article-item">
            <div class="article-header">
              <h3>{{ article.title }}</h3>
              <span>Created at: {{ new Date(article.createdAt).toLocaleDateString() }} {{ new Date(article.createdAt).toLocaleTimeString() }}</span>
            </div>
            <p>{{ article.content }}</p>
            <div class="comments">
              <h4>Comments</h4>
              <ul>
                <li v-for="comment in article.comments" :key="comment.id" class="comment-item">
                  <strong>{{ comment.username }}</strong>: {{ comment.text }}
                </li>
              </ul>
              <form @submit.prevent="addComment(article.id)" class="comment-form">
                <input v-model="newComment" type="text" placeholder="Add a comment" required />
                <button type="submit" class="btn btn-secondary">Comment</button>
              </form>
              <button v-if="isAdmin" @click="deleteAllComments(article.id)" class="btn btn-danger">Delete All Comments</button>
            </div>
            <div class="rating">
              <h4>Rate this article</h4>
              <div class="stars">
                <span v-for="star in 5" :key="'rate-' + star" class="fa fa-star" :class="{ checked: star <= article.userRating }" @click="rateArticle(article.id, star)"></span>
                <span class="rating-value">Your Rating: {{ article.userRating || 0 }} / 5</span>
              </div>
              <h4>Average Rating</h4>
              <div class="stars average">
                <span v-for="star in 5" :key="'avg-' + star" class="fa fa-star" :class="{ checked: star <= article.averageRating }"></span>
                <span class="rating-value">Average Rating: {{ article.averageRating }} / 5</span>
              </div>
            </div>
            <div class="like-section">
              <button @click="likeArticle(article.id)" class="btn btn-like"><i class="fa fa-thumbs-up"></i> Like</button>
              <span class="like-count">{{ article.likes || 0 }} Likes</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<style scoped>
.article-page {
}

.checked {
  color: orange;
}

.fa-star {
  cursor: pointer;
  font-size: 20px;
  margin-right: 5px;
}

.btn-danger {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
}

.btn-danger:hover {
  background-color: darkred;
}

.main {
  display: flex;
  height: 100vh;
  background-color: #f4f4f4;
  padding: 10px;
  box-sizing: border-box;
}

.left {
  width: 190px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.middle {
  flex: 1;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.right {
  width: 120px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 15px;
}

.createArticle {
  margin-bottom: 20px;
}

.contentBox {
  margin-bottom: 15px;
}

.contentBox label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contentBox input,
.contentBox textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.contentBox textarea {
  height: 100px;
  resize: none;
}

.contentBox button {
  width: 100%;
}

.btn-primary,
.btn-success {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-success {
  background-color: #28a745;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.btn-success:hover {
  background-color: #218838;
}

.middle {
  flex: 2;
  background-color: #f7f9fc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.article-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.article-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.article-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.article-header h3 {
  font-size: 12px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}

p {
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.comments {
  margin-top: 20px;
}

.comments h4 {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #555555;
}

.comment-item {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f0f4f8;
  border-radius: 5px;
  color: #333333;
}

.comment-form {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.comment-form input {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comment-form button {
  padding: 8px 16px;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.comment-form button:hover {
  background-color: #0056b3;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 2px 2px;
  font-size: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-danger:hover {
  background-color: #c82333;
}
/** rating */
.rating {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.rating h4 {
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}

.stars {
  display: flex;
  align-items: center;
}

.fa-star {
  cursor: pointer;
  font-size: 18px;
  margin-right: 5px;
  color: #ccc;
  transition: color 0.2s;
}

.fa-star.checked {
  color: #ffcc00;
}

.fa-star:hover {
  color: #ffcc00;
}

.fa-star:not(.checked):hover ~ .fa-star {
  color: #ccc;
}
h4 {
  margin-top: 10px;
}
.rating-value {
  margin-left: 10px;
  font-size: 10px;
  color: #666;
}

.like-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.btn-like {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.btn-like:hover {
  background-color: #0056b3;
}

.btn-like i {
  margin-right: 5px;
}

.like-count {
  font-size: 10px;
  color: #555555;
}

h2 {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #dddddd;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
}

.right {
  width: 100px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 15px;
  overflow-y: auto;
}

.resource-item {
  cursor: pointer;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.resource-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.resource-image {
  width: 100%;
  height: auto;
  display: block;
}

.resource-text {
  padding: 10px;
  text-align: center;
}

.resource-text p {
  margin: 0;
  font-size: 14px;
  color: #333333;
  font-weight: bold;
}
</style>
