import { createRouter, createWebHistory } from 'vue-router'
import AdminView from '../views/AdminView.vue'
import FirebaseRegisterView from '../views/FirebaseRegisterView.vue'
import FirebaseSigninView from '../views/FirebaseSigninView.vue'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import MentalTestView from '../views/MentalTestView.vue'
import RegisterView from '../views/registerView.vue'
import SearchLocation from '../views/SearchLocation.vue'
import SharingView from '../views/SharingView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView
    },
    {
      path: '/registerView',
      name: 'registerView',
      component: RegisterView
    },
    {
      path: '/adminView',
      name: 'adminView',
      component: AdminView,
      meta: { requiresAuth: true, role: 'Admin' }
    },
    {
      path: '/sharingView',
      name: 'sharingView',
      component: SharingView,
      meta: { requiresAuth: true }
    },
    {
      path: '/mentalTestView',
      name: 'mentalTestView',
      component: MentalTestView,
      meta: { requiresAuth: true }
    },
    {
      path: '/FireLogin',
      name: 'FireLogin',
      component: FirebaseSigninView
    },
    {
      path: '/FirebaseRegister',
      name: 'FirebaseRegister',
      component: FirebaseRegisterView
    },
    {
      path: '/searchLocation',
      name: 'SearchLocation',
      component: SearchLocation
    }
  ]
})

router.beforeEach((to, from, next) => {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedInUser) {
    // If the route requires authentication and the user is not logged in, redirect to login
    next({ name: 'Login' })
  } else {
    // Otherwise, allow access
    next()
  }
})
// router.beforeEach((to, from, next) => {
//   console.log('router-to', to)

//   if (to.meta.requiresAuth && !isAuthenticated.value) {
//     next({ name: 'Login' })
//   } else if (to.meta.role && to.meta.role === userRole.value) {
//     if (userRole.value === 'Admin') {
//       alert('The user is admin')
//       next({ name: 'adminView' })
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

export default router
