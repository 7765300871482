<script setup>
import { onMounted, ref } from 'vue'
import { getFirestore, collection, addDoc, getDocs, doc, deleteDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import router from '../router'
import Header from '../components/Header.vue'
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// init Firestore and Auth
const db = getFirestore()
const auth = getAuth()

const isLoggedIn = ref(false)
const currentPage = ref(1)
const articleCurrentPage = ref(1)
const pageSize = 10
const paginatedData = ref([])
const articlesPaginatedData = ref([])
const tableData = ref([]) 
const filteredArticlesData = ref([])
const articlesData = ref([])

const searchQueryUser = ref('')
const searchQueryArticle = ref('')

const activeForm = ref('')

// update the users' table database
const updatePaginatedData = () => {
  const start = (currentPage.value - 1) * pageSize
  const end = start + pageSize
  paginatedData.value = tableData.value.slice(start, end)
}

// update the articles' table database
const updateArticlesPaginatedData = () => {
  const start = (articleCurrentPage.value - 1) * pageSize
  const end = start + pageSize
  articlesPaginatedData.value = filteredArticlesData.value.slice(start, end)
}

// search
const handleUserSearch = () => {
  if (searchQueryUser.value) {
    paginatedData.value = tableData.value.filter(user => 
      user.email.toLowerCase().includes(searchQueryUser.value.toLowerCase())
    )
  } else {
    updatePaginatedData()
  }
}

// search by article title
const handleArticleSearch = () => {
  if (searchQueryArticle.value) {
    filteredArticlesData.value = articlesData.value.filter(article => 
      article.title.toLowerCase().includes(searchQueryArticle.value.toLowerCase())
    )
  } else {
    filteredArticlesData.value = [...articlesData.value]
  }
  updateArticlesPaginatedData()
}

// get the user data
const fetchUsers = async (role) => {
  const usersCollection = collection(db, role)
  const usersSnapshot = await getDocs(usersCollection)
  tableData.value = usersSnapshot.docs.map(doc => ({
    id: doc.id, 
    ...doc.data(),
    createdAt: doc.data().createdAt?.toDate() || new Date() // get Firestore Timestamp change to JS date object
  }))
  updatePaginatedData()
}

// get the article data
const fetchArticles = async () => {
  const articlesCollection = collection(db, 'articles')
  const articlesSnapshot = await getDocs(articlesCollection)
  articlesData.value = articlesSnapshot.docs.map(doc => ({
    id: doc.id, 
    ...doc.data(),
    createdAt: doc.data().createdAt?.toDate() || new Date()
  }))
  filteredArticlesData.value = [...articlesData.value]
  updateArticlesPaginatedData()
}

// submit the article for Firestore
const submitArticle = async () => {
  try {
    await addDoc(collection(db, 'articles'), {
      title: 'New Article',
      content: 'This is an example article',
      createdAt: serverTimestamp()
    })
    await fetchArticles()
  } catch (error) {
    console.error('Error adding article:', error)
  }
}

// delete the user
const handleDeleteUser = async (userId) => {
  await deleteDoc(doc(db, 'users', userId))
  await fetchUsers('users')
}

// delete the article
const handleDeleteArticle = async (articleId) => {
  await deleteDoc(doc(db, 'articles', articleId))
  await fetchArticles() 
}

// export users data to PDF
const exportUsersToPDF = () => {
  const doc = new jsPDF();
  doc.text('Users Data', 10, 10);
  doc.autoTable({
    head: [['Email', 'Role', 'Status', 'Created Date']],
    body: tableData.value.map(user => [
      user.email,
      user.role,
      user.isOnline ? 'Online' : 'Offline',  // Show online status
      new Date(user.createdAt).toLocaleDateString()
    ]),
  });
  
  doc.save('users-data.pdf');
};

// export articles data to PDF
const exportArticlesToPDF = () => {
  const doc = new jsPDF();
  doc.text('Articles Data', 10, 10);
  
  doc.autoTable({
    head: [['Title', 'Content', 'Created Date', 'Created Time']],
    body: articlesData.value.map(article => [
      article.title,
      article.content.substring(0, 50) + '...',  // Truncate content for preview
      new Date(article.createdAt).toLocaleDateString(),
      new Date(article.createdAt).toLocaleTimeString(),
    ]),
  });
  
  doc.save('articles-data.pdf');
}

// update user online status
const updateOnlineStatus = async (userId, isOnline) => {
  const userDoc = doc(db, 'users', userId)
  await updateDoc(userDoc, { isOnline })
}

const showUsersForm = () => {
  activeForm.value = 'users'
  fetchUsers('users')
}
const showArticlesForm = () => {
  activeForm.value = 'articles'
  fetchArticles()
}

const handleUserPageChange = (page) => {
  currentPage.value = page
  updatePaginatedData()
}

const handleArticlePageChange = (page) => {
  articleCurrentPage.value = page
  updateArticlesPaginatedData()
}

// Auth state change listener and update user online status
onMounted(() => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedIn.value = true
      const userId = user.uid
      updateOnlineStatus(userId, true) // Set user as online
      
      window.addEventListener('beforeunload', () => {
        updateOnlineStatus(userId, false) // Set user as offline on window unload
      })

      fetchUsers('users')
    } else {
      router.push({ name: 'Login' })
    }
  })
})
</script>

<template>
  <div>
    <Header />
  </div>
  <div class="main">
    <div class="left">
      <!-- nav -->
      <nav>
        <ul>
          <li>
            <a href="#" @click.prevent="showUsersForm">Users Form</a>
          </li>
          <li>
            <a href="#" @click.prevent="showArticlesForm">Articles Form</a>
          </li>
        </ul>
      </nav>
    </div>

    <div class="right">
      <!-- user table -->
      <template v-if="activeForm === 'users'">
        <div class="search-bar">
          <el-input 
            v-model="searchQueryUser" 
            placeholder="Search by email..." 
            @input="handleUserSearch" 
            clearable
          />
        </div>
        <el-button @click="exportUsersToPDF">Export Users to PDF</el-button> <!-- Export button -->
        <el-table :data="paginatedData" style="width: 100%" @sort-change="handleSortChange">
          <el-table-column prop="email" label="Email" width="250" />
          <el-table-column prop="role" label="Role" width="120" />
          <el-table-column prop="isOnline" label="Status" width="120">
            <template #default="scope">
              <span v-if="scope.row.isOnline" class="online">Online</span>
              <span v-else class="offline">Offline</span>
            </template>
          </el-table-column>
          <el-table-column 
            prop="createdAt" 
            label="Created Date" 
            width="180" 
            sortable
            :sort-method="(a, b) => new Date(a.createdAt) - new Date(b.createdAt)"
          >
            <template #default="scope">
              <!-- Display the registration date -->
              <span>{{ new Date(scope.row.createdAt).toLocaleDateString() }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="Operations" min-width="150">
            <template #default="scope">
              <el-button link type="danger" size="small" @click="handleDeleteUser(scope.row.id)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="tableData.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleUserPageChange"
        />
      </template>

      <!-- article table -->
      <template v-else-if="activeForm === 'articles'">
        <div class="search-bar">
          <el-input 
            v-model="searchQueryArticle" 
            placeholder="Search by title..." 
            @input="handleArticleSearch" 
            clearable
          />
        </div>
        <el-button @click="exportArticlesToPDF">Export Articles to PDF</el-button> <!-- Export button -->
        <el-table :data="articlesPaginatedData" style="width: 100%" @sort-change="handleSortChange">
          <el-table-column prop="title" label="Article Title" width="200">
            <template #default="scope">
              <div class="ellipsis">{{ scope.row.title }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="content" label="Article Content" width="400">
            <template #default="scope">
              <div class="ellipsis">{{ scope.row.content }}</div>
            </template>
          </el-table-column>
          <el-table-column 
            prop="createdAt" 
            label="Created Date" 
            width="180" 
            sortable
            :sort-method="(a, b) => new Date(a.createdAt) - new Date(b.createdAt)"
          >
            <template #default="scope">
              <span>{{ new Date(scope.row.createdAt).toLocaleDateString() }}</span>
            </template>
          </el-table-column>
          <el-table-column 
            prop="createdTime" 
            label="Created Time" 
            width="180" 
            sortable
            :sort-method="(a, b) => new Date(a.createdAt) - new Date(b.createdAt)"
          >
            <template #default="scope">
              <span>{{ new Date(scope.row.createdAt).toLocaleTimeString() }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="rating" label="Average Rating" width="100" />
          <el-table-column fixed="right" label="Operations" min-width="150">
            <template #default="scope">
              <el-button link type="danger" size="small" @click="handleDeleteArticle(scope.row.id)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="filteredArticlesData.length"
          :page-size="pageSize"
          :current-page="articleCurrentPage"
          @current-change="handleArticlePageChange"
        />
      </template>
    </div>
  </div>
</template>

<style scoped>
.main {
  display: flex;
  height: 100vh;
  background-color: #f4f4f4;
  padding: 10px;
  box-sizing: border-box;
}

.left {
  width: 200px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.right {
  flex: 1;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  overflow-y: auto;
}

.search-bar {
  margin-bottom: 15px;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  margin: 10px 0;
}

nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  cursor: pointer;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.online {
  color: green;
  font-weight: bold;
}

.offline {
  color: red;
  font-weight: bold;
}
</style>
