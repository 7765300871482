<template>
    <Header />
    <!-- Map Container -->
    <div ref="mapContainer" class="map-container"></div>

    <!-- Search box and button -->
    <div class="search-container">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search for mental health service centers"
        @keyup.enter="searchLocation"
        class="search-input"
      />
      <button @click="searchLocation" class="search-button">Search</button>
    </div>

    <!-- Route Information -->
    <div v-if="routeInfo.distance && routeInfo.duration" class="route-info">
      <h4>Route Information</h4>
      <p>Distance: {{ routeInfo.distance }} km</p>
      <p>Estimated Time: {{ routeInfo.duration }} mins</p>
    </div>
</template>

  
  <script setup>
  import { ref, reactive, onMounted } from 'vue';
  import mapboxgl from 'mapbox-gl';
  import axios from 'axios';
  import Header from '../components/Header.vue'
  
  // Map container and map instance
  const mapContainer = ref(null);
  const map = ref(null);
  const searchQuery = ref('');
  const routeInfo = reactive({
    distance: null,
    duration: null,
  });
  const userLocation = ref(null); // Used to store the user's current location
  
  // Initialize the map and get the user's current location
  onMounted(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoibWVuZ3h1ZW5pdSIsImEiOiJjbTI2N2p4Z2kwY2owMmtwem5qdXY4dzlnIn0.WLTu3KsioFvPoUUaKdfE-Q';
  
    // Initialize the map
    map.value = new mapboxgl.Map({
      container: mapContainer.value,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [145.1304, -37.9171],
      zoom: 20,
      attributionControl: false,
    });
  
    // Get the user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          userLocation.value = [longitude, latitude];
  
          // Use a built-in marker to mark the user's current location on the map
          new mapboxgl.Marker()
            .setLngLat(userLocation.value)
            .setPopup(
              new mapboxgl.Popup().setHTML('<h4>Edinburgh Street, Clayton, Victoria</h4>')
            )
            .addTo(map.value);
  
          // Fly the map to the user's current location
          map.value.flyTo({ center: userLocation.value, zoom: 14 });
        },
        (error) => {
          console.error('Error getting location:', error);
          alert('Unable to get your location, please make sure location services are enabled.');
        }
      );
    } else {
      alert('Your browser does not support location services.');
    }
  });
  
  // Search for a location and mark it on the map
  const searchLocation = () => {
    const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      searchQuery.value
    )}.json?access_token=${mapboxgl.accessToken}&limit=1`;
  
    axios
      .get(apiUrl)
      .then((response) => {
        const feature = response.data.features[0];
        if (!feature) return;
        const [lng, lat] = feature.center;
  
        // Mark the location on the map
        new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .setPopup(
            new mapboxgl.Popup().setHTML(
              `<h4>${feature.text}</h4><p>${feature.place_name}</p>`
            )
          )
          .addTo(map.value);
  
        // Fly the map to the search result
        map.value.flyTo({ center: [lng, lat], zoom: 14 });
  
        // Get route information
        getRoute(userLocation.value || [144.9631, -37.8136], [lng, lat]); // Get route info from the current location to the search point
      })
      .catch((error) => {
        console.error('Error searching location:', error);
      });
  };
  
  // Get route information
  const getRoute = (start, end) => {
    const directionsUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${start.join(
      ','
    )};${end.join(',')}?geometries=geojson&access_token=${mapboxgl.accessToken}`;
  
    axios
      .get(directionsUrl)
      .then((response) => {
        if (response.data.routes.length > 0) {
          const data = response.data.routes[0];
          routeInfo.distance = (data.distance / 1000).toFixed(2); // Convert to kilometers
          routeInfo.duration = (data.duration / 60).toFixed(0); // Convert to minutes
        } else {
          console.log('No routes found.');
          routeInfo.distance = null;
          routeInfo.duration = null;
        }
      })
      .catch((error) => {
        console.error('Error fetching route:', error);
        routeInfo.distance = null;
        routeInfo.duration = null;
      });
  };
  </script>
  
  <style>
  /* Map container */
  .map-container {
    width: 100%;
    height: 350px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  /* Search box and button */
  .search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .search-input {
    padding: 10px;
    border: 2px solid #3B5249;
    border-radius: 5px;
    outline: none;
    width: 300px;
    background-color: #F5F5F5;
    color: #3B5249;
  }
  .search-input:hover{
    background-color: pink;
    border-color: #2D4137;
  }
  
  .search-button {
    padding: 10px 20px;
    margin-left: 10px;
    background-color: #3B5249;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #2D4137;
  }
  
  /* Route information */
  .route-info {
    background-color: #F5F5F5;
    border: 2px solid #3B5249;
    border-radius: 10px;
    padding: 20px;
    color: #3B5249;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  </style>
