<script setup>
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import { doc, getFirestore, setDoc } from 'firebase/firestore'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Header from '../components/Header.vue'
import axios from 'axios'

const router = useRouter()

const secretKey = '/ZjSc7nqkLbWoeqBL/K4ytkUH4qvmlAsYxE02J1Uac='
const SECRET_ADMIN_CODE = 'ADMIN123' // Predefined admin code

const formData = ref({
  username: '',
  password: '',
  confirmPassword: '',
  adminCode: '' // Admin code field added
})

const errors = ref({
  username: null,
  password: null,
  confirmPassword: null,
  adminCode: null
})

const errorMessage = ref('')

// Firebase services
const auth = getAuth()
const db = getFirestore()

const validateName = (blur) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!emailPattern.test(formData.value.username)) {
    if (blur) errors.value.username = 'Invalid email format'
  } else {
    errors.value.username = null
  }
}

const validatePassword = (blur) => {
  const password = formData.value.password
  const minLength = 8
  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)
  const hasNumber = /\d/.test(password)
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password)

  if (password.length < minLength) {
    if (blur) errors.value.password = `Password must be at least ${minLength} characters long.`
  } else if (!hasUppercase) {
    if (blur) errors.value.password = 'Password must contain at least one uppercase letter.'
  } else if (!hasLowercase) {
    if (blur) errors.value.password = 'Password must contain at least one lowercase letter.'
  } else if (!hasNumber) {
    if (blur) errors.value.password = 'Password must contain at least one number.'
  } else if (!hasSpecialChar) {
    if (blur) errors.value.password = 'Password must contain at least one special character.'
  } else {
    errors.value.password = null
  }
}

const validateConfirmPassword = (blur) => {
  if (formData.value.password !== formData.value.confirmPassword) {
    if (blur) errors.value.confirmPassword = 'Passwords do not match.'
  } else {
    errors.value.confirmPassword = null
  }
}

const submitForm = () => {
  validateName(true);
  validatePassword(true);
  validateConfirmPassword(true);

  if (!errors.value.username && !errors.value.password && !errors.value.confirmPassword) {
    createUserWithEmailAndPassword(auth, formData.value.username, formData.value.password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        alert('Registration Successful:', user);

        const role = formData.value.adminCode === SECRET_ADMIN_CODE ? 'admin' : 'user';
        const collectionName = role === 'admin' ? 'admins' : 'users';

        try {
          await setDoc(doc(db, collectionName, user.uid), {
            email: formData.value.username,
            role: role
          });

          const localUsers = JSON.parse(localStorage.getItem(collectionName)) || [];
          localUsers.push({
            uid: user.uid,
            email: formData.value.username,
            role: role
          });
          localStorage.setItem(collectionName, JSON.stringify(localUsers));

          // sent the email
          try {
            const emailData = {
              to: formData.value.username,
              subject: 'Welcome to our platform!',
              text: 'Thank you for registering on our platform.',
              html: '<strong>Welcome to our platform!</strong>',
              filename: 'welcome-guide.pdf',
              filetype: 'application/pdf',
              attachmentContent: btoa('Welcome to our platform! Here is your guide.') // Example content in Base64
            };

            // Axios  Cloud Function 
            await axios.post('https://us-central1-assignment-86cd4.cloudfunctions.net/sendEmail', emailData);
            console.log('Email sent successfully!');
          } catch (emailError) {
            console.error('Error sending email:', emailError);
          }

          if (role === 'admin') {
            router.push({ name: 'adminView' });
          } else {
            router.push({ name: 'home' });
          }
        } catch (firestoreError) {
          console.error('Error storing user role in Firestore:', firestoreError);
          errorMessage.value = 'Failed to save user role: ' + firestoreError.message;
        }
      })
      .catch((firebaseError) => {
        console.log('Firebase Registration Error:', firebaseError.message);
        errorMessage.value = 'Registration failed: ' + firebaseError.message;
      });
  }
};

// const submitForm = () => {
//   validateName(true)
//   validatePassword(true)
//   validateConfirmPassword(true)

//   if (!errors.value.username && !errors.value.password && !errors.value.confirmPassword) {
//     createUserWithEmailAndPassword(auth, formData.value.username, formData.value.password)
//       .then(async (userCredential) => {
//         // Registration successful, get user information
//         const user = userCredential.user
//         alert('Registration Successful:', user)

//         // Determine user role
//         const role = formData.value.adminCode === SECRET_ADMIN_CODE ? 'admin' : 'user'
//         const collectionName = role === 'admin' ? 'admins' : 'users' // Select collection based on role

//         try {
//           // Store user information in the corresponding Firestore collection
//           await setDoc(doc(db, collectionName, user.uid), {
//             email: formData.value.username,
//             role: role
//           })

//           // Also store user information in localStorage
//           const localUsers = JSON.parse(localStorage.getItem(collectionName)) || []
//           localUsers.push({
//             uid: user.uid,
//             email: formData.value.username,
//             role: role
//           })
//           localStorage.setItem(collectionName, JSON.stringify(localUsers))

//           // Redirect to appropriate page
//           if (role === 'admin') {
//             router.push({ name: 'adminView' }) // Admin redirects to admin page
//           } else {
//             router.push({ name: 'home' }) // Regular user redirects to home page
//           }
//         } catch (error) {
//           console.error('Error storing user role in Firestore:', error)
//           errorMessage.value = 'Failed to save user role: ' + error.message
//         }
//       })
//       .catch((error) => {
//         console.log('Firebase Registration Error:', error.message)
//         errorMessage.value = 'Registration failed: ' + error.message
//       })
//   }
// }
</script>

<template>
  <div>
    <Header />
    <div class="container">
      <h1>Register</h1>
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="username" class="form-label">Username (Email)</label>
          <input
            v-model="formData.username"
            type="text"
            class="form-control"
            id="username"
            @blur="() => validateName(true)"
            @input="() => validateName(false)"
            required
          />
          <div v-if="errors.username" class="text-danger">{{ errors.username }}</div>
        </div>

        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input
            v-model="formData.password"
            type="password"
            class="form-control"
            id="password"
            @blur="() => validatePassword(true)"
            @input="() => validatePassword(false)"
            required
          />
          <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
        </div>

        <div class="mb-3">
          <label for="confirm-password" class="form-label">Confirm Password</label>
          <input
            v-model="formData.confirmPassword"
            type="password"
            class="form-control"
            id="confirm-password"
            @blur="() => validateConfirmPassword(true)"
            required
          />
          <div v-if="errors.confirmPassword" class="text-danger">{{ errors.confirmPassword }}</div>
        </div>

        <!-- New admin code input field -->
        <div class="mb-3">
          <label for="admin-code" class="form-label"
            >Admin Code (Optional for Admin Registration)</label
          >
          <input v-model="formData.adminCode" type="text" class="form-control" id="admin-code" />
          <div v-if="errors.adminCode" class="text-danger">{{ errors.adminCode }}</div>
        </div>

        <button type="submit" class="btn btn-primary">Register</button>
      </form>
      <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<style scoped>
.container {
  max-width: 400px;
  margin: 10px auto;
  padding: 20px;
  background-color: #f7f7f7; /* Light gray background */
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #325343; /* Match your theme's main color */
}

h1 {
  font-size: 14px;
  text-align: center;
  color: #325343; /* Match your theme's main color */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.mb-3 {
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  color: #325343; /* Match your theme's main color */
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
}

.btn-primary {
  background-color: #325343; /* Match your theme's main color */
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #275f3a; /* Darker shade for hover effect */
}

.text-danger {
  color: #dc3545;
  font-size: 14px;
}
</style>