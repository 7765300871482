<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-left">
        <img src="../assets/icons/icon.png" alt="Logo" class="footer-logo" />
        <p>© 2024 MentalHelp</p>
      </div>
      <div class="footer-links-container">
        <ul class="footer-links">
          <li><a href="#">Home</a></li>
          <li><a href="#">About</a></li>
          <li><a href="#">Reviews</a></li>
          <li><a href="#">Advice</a></li>
        </ul>
        <ul class="footer-links">
          <li><a href="#">Privacy Policy</a></li>
          <li><a href="#">Find a Therapist</a></li>
          <li><a href="#">Online Therapy</a></li>
          <li><a href="#">Terms & Conditions</a></li>
        </ul>
        <ul class="footer-links">
          <li><a href="#">Sharing Settings</a></li>
          <li><a href="#">Web Accessibility</a></li>
        </ul>
      </div>
      <div class="footer-right">
        <div class="footer-contact">
          <p><i class="fa fa-map-marker"></i> Offices around Australia</p>
          <p><i class="fa fa-envelope"></i> admin@gmail.com</p>
        </div>
        <ul class="footer-social">
          <li>
            <a href="#"><i class="fa fa-linkedin"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa fa-twitter"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa fa-instagram"></i></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<style scoped>
/* 应用在整个页面的父容器上 */
html,
body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* 修改 .box 以填满视口高度并让内容自动撑开 */
.box {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 确保 .box 最小高度为视口高度 */
}

/* 设置 .HomeContainer 占据剩余空间 */
.HomeContainer {
  flex: 1;
}

/* 修改 footer 样式 */
.footer {
  background-color: #3e4f42;
  color: #ffffff;
  padding: 10px 0;
  margin-top: auto; /* 将 footer 推到页面的底部 */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-left {
  flex: 1;
}

.footer-logo {
  display: block;
  margin-bottom: 15px;
  width: 50px;
}

.footer-links-container {
  display: flex;
  flex: 2;
  justify-content: space-between;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0 20px;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 8px;
}

.footer-right {
  flex: 1;
  text-align: right;
}

.footer-contact p {
  margin: 0 0 10px;
}

.footer-social {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.footer-social li {
  margin-bottom: 10px;
}

.footer-social li a {
  color: #ffffff;
  font-size: 18px;
  transition: color 0.3s ease;
}

.footer-social li a:hover {
  color: #00aaff;
}

.footer-contact p i {
  margin-right: 10px;
}
</style>
