import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createApp } from 'vue'
import App from './App.vue'
//import './assets/main.css'
import './assets/reset.css'
import router from './router'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAF9oLXSdFXXMtle-RCE2hZ8RP_lzqpZSs',
  authDomain: 'assignment-86cd4.firebaseapp.com',
  projectId: 'assignment-86cd4',
  storageBucket: 'assignment-86cd4.appspot.com',
  messagingSenderId: '501022033431',
  appId: '1:501022033431:web:ba4987b0d45b7539dd7e52'
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const vueApp = createApp(App)

vueApp.use(router)

vueApp.use(ElementPlus)

vueApp.mount('#app')
