<script setup>
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import Header from '../components/Header.vue'

const router = useRouter()

const errorMessage = ref('')
const formData = ref({
  username: '',
  password: '',
  confirmPassword: ''
})

const errors = ref({
  username: null,
  password: null,
  confirmPassword: null
})

// Firebase authentication and Firestore instances
const auth = getAuth()
const db = getFirestore()

const validateName = (blur) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!emailPattern.test(formData.value.username)) {
    if (blur) errors.value.username = 'Invalid email format'
  } else {
    errors.value.username = null
  }
}

const validatePassword = (blur) => {
  const password = formData.value.password
  const minLength = 8
  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)
  const hasNumber = /\d/.test(password)
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password)

  if (password.length < minLength) {
    if (blur) errors.value.password = `Password must be at least ${minLength} characters long.`
  } else if (!hasUppercase) {
    if (blur) errors.value.password = 'Password must contain at least one uppercase letter.'
  } else if (!hasLowercase) {
    if (blur) errors.value.password = 'Password must contain at least one lowercase letter.'
  } else if (!hasNumber) {
    if (blur) errors.value.password = 'Password must contain at least one number.'
  } else if (!hasSpecialChar) {
    if (blur) errors.value.password = 'Password must contain at least one special character.'
  } else {
    errors.value.password = null
  }
}

const validateConfirmPassword = (blur) => {
  if (formData.value.password !== formData.value.confirmPassword) {
    if (blur) errors.value.confirmPassword = 'Passwords do not match.'
  } else {
    errors.value.confirmPassword = null
  }
}

const submitForm = async () => {
  validateName(true)
  validatePassword(true)
  validateConfirmPassword(true)

  if (errors.value.username || errors.value.password || errors.value.confirmPassword) {
    errorMessage.value = 'Please correct the errors before logging in.'
    return
  }

  try {
    // Use Firebase to login
    const userCredential = await signInWithEmailAndPassword(
      auth,
      formData.value.username,
      formData.value.password
    )
    console.log('Firebase Login Successful!')

    const user = userCredential.user

    // Get the user's role from Firestore
    let userRole = 'user' // Default role is 'user'
    let userDoc = null

    // First, check if the user exists in the 'admins' collection
    const adminDocRef = doc(db, 'admins', user.uid)
    const adminDoc = await getDoc(adminDocRef)
    if (adminDoc.exists()) {
      userRole = 'admin'
      userDoc = adminDoc.data()
    } else {
      // If not in 'admins', check the 'users' collection
      const userDocRef = doc(db, 'users', user.uid)
      const userDocSnap = await getDoc(userDocRef)
      if (userDocSnap.exists()) {
        userRole = 'user'
        userDoc = userDocSnap.data()
      }
    }

    if (!userDoc) {
      throw new Error('User role information could not be retrieved.')
    }

    // Save user and role information in local storage
    localStorage.setItem('loggedInUser', JSON.stringify({ user: auth.currentUser, role: userRole }))

    // Redirect based on the user role
    if (userRole === 'admin') {
      router.push({ name: 'adminView' }) // Redirect admin users to adminView page
    } else {
      router.push({ name: 'home' }) // Redirect normal users to home page
    }
  } catch (error) {
    console.error('Firebase Login Error:', error.message)
    errorMessage.value = 'Invalid username or password'
  }
}

const clearForm = () => {
  formData.value = {
    username: '',
    password: '',
    confirmPassword: ''
  }
}

const registerUser = () => {
  router.push({ name: 'registerView' })
}
</script>

<template>
  <div>
    <Header />
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <ul class="logo">
            <img src="@/assets/icons/icon.png" />
            <h1 class="theme">Log into my account</h1>
          </ul>

          <form @submit.prevent="submitForm">
            <div class="mb-3">
              <label for="username" class="form-label">Username (Email)</label>
              <input
                type="text"
                class="form-control"
                id="username"
                @blur="() => validateName(true)"
                @input="() => validateName(false)"
                v-model="formData.username"
              />
              <div v-if="errors.username" class="text-danger">{{ errors.username }}</div>
            </div>

            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <input
                type="password"
                class="form-control"
                id="password"
                @blur="() => validatePassword(true)"
                @input="() => validatePassword(false)"
                v-model="formData.password"
              />
              <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
            </div>

            <div class="mb-3">
              <label for="confirm-password" class="form-label">Confirm Password</label>
              <input
                type="password"
                class="form-control"
                id="confirm-password"
                v-model="formData.confirmPassword"
                @blur="() => validateConfirmPassword(true)"
              />
              <div v-if="errors.confirmPassword" class="text-danger">
                {{ errors.confirmPassword }}
              </div>
            </div>

            <div class="text-center">
              <button type="submit" class="btn btn-primary me-2">Login</button>
              <button type="button" class="btn btn-secondary me-2" @click="clearForm">Clear</button>
              <button type="button" class="btn btn-secondary me-2" @click="registerUser">
                Register
              </button>
            </div>
          </form>
          <p v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Keep the styles unchanged */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 50vw;
  margin: 0 auto;
  padding: 20px;
  background-color: #325343;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  width: 100%;
}

.logo {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.theme {
  font-size: 16px;
  color: aliceblue;
  margin-top: -4px;
}

img {
  height: 15px;
  margin-right: 5px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  color: aliceblue;
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.text-danger {
  margin-top: 5px;
  font-size: 14px;
}

.text-center {
  margin-top: 20px;
}
</style>