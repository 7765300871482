<script setup>
import { onMounted, ref } from 'vue'
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'

const isLoggedIn = ref(false)

onMounted(() => {
  // check user whether login or not
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  if (user) {
    isLoggedIn.value = true
  } else {
    isLoggedIn.value = false
  }
})
</script>

<template>
    
  <div class="box">
    <!-- Skip to content link for keyboard users -->
    <a href="/searchLocation" class="skip-link">Skip to the map</a>
    <Header />
    <div class="HomeContainer">
      <h1 class="main-title">You deserve to be happy</h1>
      <h2 class="subtitle">What type of therapy are you looking for?</h2>
      <div class="therapy-options">
        <div class="therapy-card">
          <img src="../assets/imgs/home.png" alt="Individual Therapy" />
          <div class="therapy-info">
            <h3>Individual</h3>
            <p>For myself</p>
          </div>
        </div>
        <div class="therapy-card">
          <img src="../assets/imgs/home1.png" alt="Couples Therapy" />
          <div class="therapy-info">
            <h3>Couples</h3>
            <p>For me and my partner</p>
          </div>
        </div>
        <div class="therapy-card">
          <img src="../assets/imgs/home2.png" alt="Teen Therapy" />
          <div class="therapy-info">
            <h3>Teen</h3>
            <p>For my child</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style scoped>
.box {
  width: 100%;
  margin: 0;
  background-color: #325343;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.logo {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.theme {
  font-size: 16px;
  color: aliceblue;
  margin-top: -4px;
}

img {
  height: 15px;
  margin-right: 5px;
}

.title {
  display: flex;
}

.tag {
  margin-right: 5px;
}

.HomeContainer {
  text-align: center;
  background-color: #325343;
  padding: 40px 20px;
  color: #ffffff;
}

.main-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.therapy-options {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.therapy-card {
  background-color: #ffffff;
  color: #333333;
  border-radius: 10px;
  overflow: hidden;
  width: 250px;
  transition: transform 0.3s ease;
}

.therapy-card:hover {
  transform: translateY(-10px);
}

.therapy-card img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.therapy-info {
  padding: 20px;
}

.therapy-info h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.therapy-info p {
  font-size: 1rem;
  color: #777777;
}

.skip-link {
  position: absolute;
  top: 6px; 
  left: 370px; 
  background-color: #F5F5F5;
  color: #325343; 
  padding: 2px 5px; 
  border-radius: 5px; 
  z-index: 100;
  font-size: 14px;
  text-decoration: none; 
  transition: background-color 0.3s ease, color 0.3s ease; 
}

.skip-link:focus {
  background-color: #3B5249; 
  color: #FFFFFF; 
}

</style>
