<template>
  <div>
    <Header />
    <div class="container">
      <h1>Mental Health Assessment</h1>
      <form @submit.prevent="submitForm">
        <div v-for="(question, index) in questions" :key="index" class="question">
          <label :for="'question-' + index">{{ question.text }}</label>
          <select v-model="question.score" :id="'question-' + index" required>
            <option :value="0">Not at all</option>
            <option :value="1">Several days</option>
            <option :value="2">More than half the days</option>
            <option :value="3">Nearly every day</option>
          </select>
        </div>
        <button type="submit">Submit</button>
        <button type="button" @click="clearForm">Clear</button>
      </form>
      <div v-if="result" class="result">
        <h2>Your Assessment Result</h2>
        <p>{{ result }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Header from '../components/Header.vue'

const questions = ref([
  { text: 'Little interest or pleasure in doing things?', score: 0 },
  { text: 'Feeling down, depressed, or hopeless?', score: 0 },
  { text: 'Trouble falling or staying asleep, or sleeping too much?', score: 0 },
  { text: 'Feeling tired or having little energy?', score: 0 },
  { text: 'Poor appetite or overeating?', score: 0 }
])

const result = ref(null)

const submitForm = () => {
  const totalScore = questions.value.reduce((acc, question) => acc + question.score, 0)
  if (totalScore <= 4) {
    result.value = 'Normal'
  } else if (totalScore <= 9) {
    result.value = 'Mild Depression'
  } else if (totalScore <= 14) {
    result.value = 'Moderate Depression'
  } else {
    result.value = 'Severe Depression'
  }
}

const clearForm = () => {
  questions.value.forEach((question) => {
    question.score = 0 // Reset each question's score to 0, which corresponds to "Not at all"
  })
  result.value = null // Clear the result display
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');

.container {
  max-width: 500px;
  margin: 10px auto;
  padding: 20px;
  background-color: #325343;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  font-family: 'Merriweather', serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #ffda79;
  font-weight: 700;
}

.question {
  margin-bottom: 20px;
}

label {
  font-size: 10px;
  font-weight: 700;
  color: #ffda79;
}

select {
  display: block;
  margin-top: 8px;
  padding: 5px;
  border-radius: 6px;
  width: 100%;
  border: 2px solid #ffffff;
  background-color: #4f6f5e;
  color: #ffffff;
  font-size: 8px;
  font-family: 'Merriweather', serif;
}

select option {
  background-color: #ffffff;
  color: #000000;
}

button {
  padding: 10px 20px;
  background-color: #ffda79;
  color: #325343;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Merriweather', serif;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

button:hover {
  background-color: #ffc107;
}

.result {
  margin-top: 30px;
  padding: 20px;
  background-color: #ffda79;
  border-radius: 10px;
  text-align: center;
  color: #325343;
  font-family: 'Merriweather', serif;
  font-size: 18px;
  font-weight: 400;
}

.result h2 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
}
</style>
