<script setup>
import { computed, onMounted, ref } from 'vue'
import router from '../router'

const isLoggedIn = ref(false)
const user = ref(null) // This ref will store the logged-in user's information

onMounted(() => {
  // Fetch the user from localStorage
  const storedUser = JSON.parse(localStorage.getItem('loggedInUser'))
  if (storedUser) {
    user.value = storedUser
    isLoggedIn.value = true
  } else {
    isLoggedIn.value = false
  }
})

// Computed property to check if the logged-in user is an admin
const isAdmin = computed(() => {
  return user.value && user.value.role === 'Admin'
})

const handleAuthAction = () => {
  if (isLoggedIn.value) {
    localStorage.removeItem('loggedInUser')
    isLoggedIn.value = false
    user.value = null // Reset the user ref
    router.push({ name: 'Login' })
  } else {
    router.push({ name: 'Login' })
  }
}

const goBackHome = () => {
  router.push({ name: 'home' })
}

const goBackManagement = () => {
  if (isAdmin.value) {
    router.push({ name: 'adminView' })
  }
}
</script>

<template>
  <div class="box">
    <div class="header">
      <ul class="logo" @click="goBackHome">
        <img src="@/assets/icons/icon.png" />
        <li class="theme">Mental help</li>
      </ul>
      <ul class="title">
        <!-- Management button only visible to admin users -->
        <button
          v-if="isAdmin"
          type="button"
          class="btn btn-secondary"
          style="margin-right: 10px"
          @click="goBackManagement"
        >
          Management
        </button>
        <li class="tag">
          <div class="dropdown">
            <a
              class="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Services
            </a>

            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" to="/MentalTestView">Mental test</router-link>
              </li>
              <li><a class="dropdown-item" href="#">Health Course Reservation</a></li>
            </ul>
          </div>
        </li>
        <li class="tag">
          <div class="dropdown">
            <a
              class="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              About
            </a>

            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="/searchLocation">map</a></li>
            </ul>
          </div>
        </li>
        <li class="tag">
          <div class="dropdown">
            <a
              class="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Communication
            </a>

            <ul class="dropdown-menu">
              <li><router-link class="dropdown-item" to="/SharingView">Sharing</router-link></li>
            </ul>
          </div>
        </li>
        <!-- <button type="button" class="btn btn-light">
          <router-link to="/FireLogin">Firebase Login</router-link>
        </button> -->
        <button type="button" class="btn btn-light" @click="handleAuthAction">
          {{ isLoggedIn ? 'Logout' : 'Login' }}
        </button>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.box {
  width: 100%;
  margin: 0 auto;
  background-color: #325343;
  height: 50%;
}
.header {
  display: flex;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
.logo {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.theme {
  font-size: 16px;
  color: aliceblue;
  margin-top: -4px;
}
img {
  height: 15px;
  margin-right: 5px;
}
.title {
  display: flex;
}
.tag {
  margin-right: 5px;
}
</style>
